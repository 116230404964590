@import ../../styles/helpers

.label
    margin-bottom: 12px
    +hairline-2
    color: $n5

.wrap
    position: relative

.input
    width: 100%
    height: 48px
    padding: 0 14px
    border-radius: 12px
    border: 2px solid $n6
    background: $n8
    +poppins
    +caption-bold-1
    color: $n2
    transition: border-color .2s
    cursor: pointer
    +dark
        border-color: $n3
        background: none
        color: $n8
    &::file-selector-button
        padding: 0 14px
        height: 80%
        border: none
        background: $n6
        color: $n4
        border-radius: 10px
        +poppins
        +caption-bold-1
        cursor: pointer
        transition: background-color .2s, color .2s
        align-items: center
        justify-content: center
        margin: 1% // Centrado vertical
        &:hover
            background: $n5
            color: $n1
        +dark
            background: $n3
            color: $n8
            &:hover
                background: $n3
                color: $n8
    &:focus
        border-color: $n4

.preview
    position: absolute
    top: 0
    right: 0
    bottom: 0
    width: 48px
    display: flex
    justify-content: center
    align-items: center
    svg
        fill: $n4

.note
    margin-top: 12px
    +caption-3
    font-weight: 500
    color: $n4