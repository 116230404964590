@import ../../../styles/helpers

.charts
    position: relative
    z-index: 2
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1)
    border-radius: 4px
    img
        width: 100%

.head
    display: flex
    justify-content: space-between
    align-items: center
    padding: 16px
    border-radius: 4px
    background: $n8
    border-bottom: 1px solid $n6
    +dark
        background: #17181B
        border-color: $n2

.nav
    display: flex

.group
    .nav
        +m
            display: none

.dropdown
    display: none
    +m
        display: block

.dropdownHead
    height: 28px
    line-height: 28px
    background: $n6
    +dark
        background: $n3

.link
    display: flex
    padding: 6px 12px
    border-radius: 14px
    background: none
    +button-2
    color: $n4
    transition: all .2s
    &:hover
        color: $n3
        +dark
            color: $n6
    &.active
        background: $n6
        color: $n2
        +dark
            background: $n3
            color: $n8
    &:not(:last-child)
        margin-right: 8px

.inner
    overflow: hidden

.iframe
    height: 490px
    margin: -1px
    height: 488px
    +t
        position: relative
        z-index: -1

.chart
    width: 100%
    height: 488px
    background: $n8
    +dark
        background: #17181B