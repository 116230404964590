@import ../../../styles/helpers

.control
    display: flex
    justify-content: space-between
    margin-bottom: 64px
    +m
        display: block
        margin-bottom: 48px

.field,
.wrap,
.box,
.checkbox
    &:not(:last-child)
        margin-bottom: 32px

.back
    display: flex
    align-items: center
    +poppins
    +body-bold-1
    +dark
        color: $n8
    svg
        margin-right: 16px
        fill: $n4
        transition: transform .2s
    &:hover
        svg
            transform: translateX(-2px)

.box
    position: relative
    .button
        position: absolute
        top: 36px
        right: 16px
        height: 24px
        padding: 0 16px
        border-radius: 12px

.money
    display: flex
    align-items: center
    +body-bold-2
    +m
        display: none
    img
        margin-left: 12px
        width: 24px

.wrap
    display: flex
    padding: 20px 24px
    border-radius: 4px
    background: $n7
    +dark
        background: $n3

.payment
    display: flex
    justify-content: center
    align-items: flex-start
    margin-bottom: 12px

.category
    margin-right: auto
    font-weight: 500
    color: $n3
    +dark
        color: $n7

.field
    position: relative

.value,
.input
    +dm-sans
    font-weight: 700
    font-size: 96px
    line-height: 1
    letter-spacing: -.02em
    color: $n2
    +m
        font-size: 64px

.value
    min-width: 72px
    max-width: 500px
    min-height: 96px
    padding-right: 5px
    opacity: 0
    +m
        min-height: 64px
        max-width: 280px
        padding-bottom: 10px

.input
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    border: none
    background: none
    box-shadow: none
    color: $n2
    +dark
        color: $n8

.sign
    position: relative
    top: 8px
    margin-left: 8px
    +m
        top: 1px

.price
    margin-bottom: 12px
    text-align: center
    +body-bold-2
    color: $n4
    span
        margin: 0 4px
        color: $n2
        +dark
            color: $n8

.btns
    margin-top: 48px
    text-align: center
    .button
        +m
            width: 100%

.fieldL
    display: flex
    align-items: center
    padding: 0 16px
    border-radius: 12px
    box-shadow: inset 0 0 0 2px $n6
    cursor: pointer
    +dark
        box-shadow: inset 0 0 0 2px $n2
    &:not(:last-child)
        margin-bottom: 12px

.label,
.currencyy
    font-weight: 500
    color: $n4
    pointer-events: none

.inputt
    flex-grow: 1
    height: 48px
    padding: 0 10px
    background: none
    text-align: right
    +poppins
    font-size: 14px
    font-weight: 500
    color: $n2
    +dark
        color: $n8