
.progressBarContainer
  position: relative
  height: 20px
  width: 100%
  background-color: #e0e0e0
  border-radius: 10px
  overflow: hidden

.progressBar 
  height: 100%
  background-color: #4caf50
  transition: width 0.3s

.progressText 
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  font-size: 14px
  color: #000