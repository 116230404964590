@import ../../../../styles/helpers

.successfully
    padding-top: 40px

.title
    margin-bottom: 32px
    text-align: center

.info
    max-width: 280px
    margin: 0 auto 32px
    text-align: center
    +body-bold-2
    span
        color: $p4

.list
    display: flex
    flex-wrap: wrap
    padding: 24px
    border-radius: 12px
    border: 1px solid $n6
    +dark
        border-color: $n3

.item
    &:first-child
        margin-right: auto

.category
    margin-bottom: 10px
    color: $n4

.content
    font-weight: 500
    color: $n2
    +dark
        color: $n8

.btns
    display: flex
    margin: 32px -8px 0

.button
    flex: 0 0 calc(50% - 16px)
    width: calc(50% - 16px)
    margin: 0 8px

.loadingDots
  display: flex
  justify-content: center
  margin-top: 20px

  .dot
    width: 15px
    height: 15px
    background-color: #faca32
    border-radius: 50%
    margin: 0 5px
    opacity: 0
    animation: dotFlashing 1.5s infinite ease-in-out
  

  .dot:nth-child(1)
    animation-delay: 0s
  
  .dot:nth-child(2)
    animation-delay: 0.3s
  
  .dot:nth-child(3)
    animation-delay: 0.6s
  


@keyframes dotFlashing
  0%,
  20%
    opacity: 0
  
  40%
    opacity: 1
  
  100%
    opacity: 0
  

