
.circlesContainer
  display: flex
  align-items: center
  
.circle
  width: 15px
  height: 15px
  border-radius: 50%
  margin-right: 5px
  transition: background-color 0.3s
  
.circlesText
  margin-left: 10px
  font-size: 14px
  